<template>
  <div class="container">
    <div class="user-nav">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <el-select
            v-model="termName"
            placeholder="当前学期"
            style="width: 195px"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in termlist"
              :key="index"
              :label="item.termName"
              :value="item.termName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <div class="userinfo">
            <p>{{ paramsData.userName }}</p>
            <span>学籍号：{{ paramsData.studentCode }}</span>
            <span>{{ paramsData.className }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <div class="table-box">
        <h4>各评价维度成绩</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>我的综素</th>
            <th>思想品德</th>
            <th>学业水平</th>
            <th>身心健康</th>
            <th>艺术素养</th>
            <th>社会实践（初中累计）</th>
          </tr>
          <tr>
            <td>目前综素成绩</td>
            <td>{{ dimensionBo.type301 }}</td>
            <td>{{ dimensionBo.type309 }}</td>
            <td>{{ dimensionBo.type320 }}</td>
            <td>{{ dimensionBo.type333 }}</td>
            <td>{{ dimensionBo.type341 }}</td>
          </tr>
		  <tr>
			  <td>综素等级</td>
			  <td>{{ dimensionBo.type301Level }}</td>
			  <td>{{ dimensionBo.type309Level }}</td>
			  <td>{{ dimensionBo.type320Level }}</td>
			  <td>{{ dimensionBo.type333Level }}</td>
			  <td>{{ dimensionBo.type341Level }}</td>
		  </tr>
          <tr>
            <td>详情</td>
            <td><el-button type="text" @click="handleClickMor">查看</el-button></td>
            <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
            <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
            <td><el-button type="text" @click="handleClickArt">查看</el-button></td>
            <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
          </tr>
        </table>
      </div>
      <div class="more">
        <h4>主要评价分项成绩</h4>
        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>思想品德</h5>
          </el-col>
        </el-row>
        <div class="table-inbox">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th>分项</th>
              <th>写实记录</th>
              <th>违纪记录</th>
              <th>荣誉记录</th>
            </tr>
            <tr>
              <td>综素成绩</td>
              <td>{{ dimensionBo.type301And200 }}</td>
              <td>{{ dimensionBo.type301And460 }}</td>
              <td>{{ dimensionBo.type301And451 }}</td>
            </tr>
            <tr>
              <td>详情</td>
              <td><el-button type="text" @click="handleClickRecord">查看</el-button></td>
              <td><el-button type="text" @click="handleClickMor">查看</el-button></td>
              <td><el-button type="text" @click="handleClickMor">查看</el-button></td>
            </tr>
          </table>
        </div>

        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>学业水平</h5>
          </el-col>
        </el-row>
        <div class="table-inbox academic">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th>分项</th>
              <th>写实记录</th>
              <th>平时成绩</th>
              <th>期中成绩</th>
              <th>期末成绩</th>
              <th>学科比赛</th>
              <th>课堂表现评价</th>
            </tr>
            <tr>
              <td>综素成绩</td>
              <td>{{ dimensionBo.type309And200 }}</td>
              <td>{{ dimensionBo.type309AndCommon }}</td>
              <td>{{ dimensionBo.type309AndMiddle }}</td>
              <td>{{ dimensionBo.type309AndFinal }}</td>
              <td>{{ dimensionBo.type309And458 }}</td>
              <td>{{ dimensionBo.type309And478 }}</td>
            </tr>
            <tr>
              <td>详情</td>
              <td><el-button type="text" @click="handleClickRecord">查看</el-button></td>
              <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
              <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
              <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
              <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
              <td><el-button type="text" @click="handleClickAca">查看</el-button></td>
            </tr>
          </table>
        </div>

        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>身心健康</h5>
          </el-col>
        </el-row>
        <div class="table-inbox health">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th>分项</th>
              <th>写实记录</th>
              <th>体质检测</th>
              <th>心理测评</th>
              <th>健康知识测评</th>
              <th>体育出勤率</th>
              <th>体育比赛</th>
            </tr>
            <tr>
              <td>综素成绩</td>
              <td>{{ dimensionBo.type320And200 }}</td>
              <td>{{ dimensionBo.type320And403 }}</td>
              <td>{{ dimensionBo.type320And400 }}</td>
              <td>{{ dimensionBo.type320And401 }}</td>
              <td>{{ dimensionBo.type320And466 }}</td>
              <td>{{ dimensionBo.type320And454 }}</td>
            </tr>
            <tr>
              <td>详情</td>
              <td><el-button type="text" @click="handleClickRecord">查看</el-button></td>
              <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
              <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
              <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
              <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
              <td><el-button type="text" @click="handleClickHea">查看</el-button></td>
            </tr>
          </table>
        </div>

        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>艺术素养</h5>
          </el-col>
        </el-row>
        <div class="table-inbox">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th>分项</th>
              <th>写实记录</th>
              <th>艺术知识测评</th>
              <th>艺术比赛</th>
            </tr>
            <tr>
              <td>综素成绩</td>
              <td>{{ dimensionBo.type333And200 }}</td>
              <td>{{ dimensionBo.type333And402 }}</td>
              <td>{{ dimensionBo.type333And456 }}</td>
            </tr>
            <tr>
              <td>详情</td>
              <td><el-button type="text" @click="handleClickRecord">查看</el-button></td>
              <td><el-button type="text" @click="handleClickArt">查看</el-button></td>
              <td><el-button type="text" @click="handleClickArt">查看</el-button></td>
            </tr>
          </table>
        </div>

        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>社会实践</h5>
          </el-col>
        </el-row>
        <div class="table-inbox academic">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th>分项</th>
              <th>设计制作</th>
              <th>劳动体验</th>
              <th>社会服务</th>
              <th>素质拓展</th>
              <th>考察探究</th>
            </tr>
            <tr>
              <td>综素成绩</td>
              <td>{{ dimensionBo.type341And468 }}</td>
              <td>{{ dimensionBo.type341And470 }}</td>
              <td>{{ dimensionBo.type341And472 }}</td>
              <td>{{ dimensionBo.type341And474 }}</td>
              <td>{{ dimensionBo.type341And476 }}</td>
            </tr>
            <tr>
              <td>详情</td>
              <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
              <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
              <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
              <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
              <td><el-button type="text" @click="handleClickSoc">查看</el-button></td>
            </tr>
          </table>
        </div>

        <div class="back">
            <el-button type="text" @click="backIndex">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSummaryDetail } from "@/api/teacher/quality/summary";
export default {
  data() {
    return {
      termName: "",
      termlist: [],
      paramsData: {},
      dimensionBo: {},
    };
  },
  created() {
    this.$store.dispatch('GetTermList').then(res=>{
        this.termlist = res.data
    })
    this.paramsData = this.$route.params.userName != undefined ? this.$route.params : JSON.parse(sessionStorage['params'])
    this.termName = this.paramsData.termName
    this.getList()
  },
  methods: {
    handleChange(val) {
      this.termName = val
      this.getList() 
    },
    getList() {
      getSummaryDetail({userId: this.paramsData.userId,term: this.termName}).then(res => {
        this.dimensionBo = res.data.dimensionBo
      })
    },
    handleClickRecord() {
      this.$router.push({name: 'personal',params: this.paramsData})
    },
    handleClickMor() {
      this.$router.push({name: 'studentResults',params: this.paramsData})
      sessionStorage['params'] = JSON.stringify(this.paramsData)
    },
    handleClickAca() {
      this.$router.push({name: 'academicStudent',params: this.paramsData})
      sessionStorage['params'] = JSON.stringify(this.paramsData)
    },
    handleClickHea() {
      this.$router.push({name: 'healthStudent',params: this.paramsData})
      sessionStorage['params'] = JSON.stringify(this.paramsData)
    },
    handleClickArt() {
      this.$router.push({name: 'artStudent',params: this.paramsData})
      sessionStorage['params'] = JSON.stringify(this.paramsData)
    },
    handleClickSoc() {
      this.$router.push({name: 'socialStudent',params: this.paramsData})
      sessionStorage['params'] = JSON.stringify(this.paramsData)
    },
    backIndex() {
      this.$router.go(-1);      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 16%;
          }
        }
      }
    }
    .more {
      .table-inbox {
        width: 100%;
        margin-bottom: 10px;
        table {
          width: 100%;
          border: 1px solid #ebeef5;
          tr {
            height: 48px;
            th {
              width: 25%;
              font-size: 14px;
              background-color: rgb(246, 247, 250);
              color: rgb(145, 147, 154);
            }
            td {
              font-size: 14px;
              color: #666666;
              text-align: center;
            }
          }
        }
      }
      .academic {
        table {
          tr {
            th {
              width: 14%;
            }
          }
        }
      }
      .health {
        table {
          tr {
            th {
              width: 14%;
            }
          }
        }  
      }
    }
  }
}
</style>

<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>